<template>
  <div
    :class="{'padding--displayed': !isMainPage || showMainNavBar}"
    class="he-ui-page h-100">
    <TabsContainer
      v-if="showMainNavBar"
      v-model="tabValue"
      :tabs="tabs"
      @closeSession="() => showCloseSessionModal = true">
    </TabsContainer>
    <section
        :class="{'padding--displayed': showMainNavBar}"
        class="he-ui-page-content d-flex flex-column justify-content-between">
        <router-view />
      </section>
  </div>
  <portal to="modal-place">
    <CloseSessionModal
      v-if="showCloseSessionModal"
      :title="$t('main.close_session')"
      :subtitle="$t('main.close_session_question')"
      image="close_session"
      :buttons="buttons"/>
  </portal>
  <portal to="modal-place">
    <SensorNotFound
      v-if="showSensorNotFoundModal"
      :title="$t('main.sensor_not_found')"
      :subtitle="$t('main.reconnect_sensor_again')"
      image="sensor_not_found"
      :button="{text: $t('main.try_again'), handler: this.onTryAgain}"/>
  </portal>
  <portal to='modal-place'>
    <SensorNotFound
      v-if="showWebSocketDisconnectedModal"
      :title="$t('main.error_connection')"
      :subtitle="$t('main.error_websocket_connection')"
      image="sensor_not_found"
      :button="{text: $t('main.try_again'), handler: this.onTryAgainWSconnection}"
    />
  </portal>
  <portal-target
    name="modal-place" />
</template>

<script>
import {
  LOCATION_OVERVIEW_PAGE_ROUTE_NAME,
  MAIN_PAGE_ROUTE_NAME,
  STATISTICS_OVERVIEW_PAGE_ROUTE_NAME
} from '@/router/routes'
import { mapGetters } from 'vuex'
import CloseSessionModal from '@/components/CloseSessionModal'
import SensorNotFound from '@/components/SensorNotFound'
import TabsContainer from '@/components/TabsContainer'
import auth from '@/communication/auth'
import fingerprintReader from '@/composition/fingerprintReader'
import imgTabIdentification from '@/assets/check-ring-white.svg'
import imgTabLocation from '@/assets/ubication-pin-white.svg'
import imgTabStatistics from '@/assets/chart-white.svg'

export default {
  name: 'App',
  components: {
    CloseSessionModal,
    TabsContainer,
    SensorNotFound
  },
  setup () {
    const { captureFingerprint, getDeviceCount, showSensorNotFoundModal, showWebSocketDisconnectedModal } = fingerprintReader()

    return {
      captureFingerprint,
      getDeviceCount,
      showSensorNotFoundModal,
      showWebSocketDisconnectedModal
    }
  },
  data () {
    return {
      tabs: [
        { id: 0, text: 'navigationPills.identification', icon: imgTabIdentification, disabled: false, href: MAIN_PAGE_ROUTE_NAME },
        { id: 1, text: 'navigationPills.location', icon: imgTabLocation, disabled: false, href: LOCATION_OVERVIEW_PAGE_ROUTE_NAME },
        { id: 2, text: 'navigationPills.statistics', icon: imgTabStatistics, disabled: false, href: STATISTICS_OVERVIEW_PAGE_ROUTE_NAME }
        // { id: 3, text: 'main.medication', disabled: false, href: MEDICATION_OVERVIEW_PAGE_ROUTE_NAME },
        // { id: 3, text: 'main.sensor', icon: 'sensor_window', disabled: false, href: SENSOR_OVERVIEW_PAGE_ROUTE_NAME }
        // { id: 4, text: 'main.scan', disabled: false, href: SCAN_FINGER_PAGE_ROUTE_NAME }
      ],
      tabValue: 0,
      showCloseSessionModal: false
    }
  },
  watch: {
    tabValue (value) {
      this.$router.push({
        name: this.tabs[value].href
      })
    },
    $route () {
      const tabIndex = this.tabs?.find(item => item.href === this.$route.name)?.id
      if (tabIndex) this.tabValue = tabIndex
    }
  },
  computed: {
    ...mapGetters([
      'getLoggedStaff',
      'isMainNavBarEnabled'
    ]),
    buttons () {
      return [
        { text: this.$t('main.cancel'), template: 'white', handler: () => { this.showCloseSessionModal = false } },
        { text: this.$t('main.close_session'), template: 'blue', handler: this.logout }
      ]
    },
    showMainNavBar () {
      return this.isPageWithMainNavBar && (this.getLoggedStaff || auth.checkAuth())
    },
    isMainPage () {
      return [MAIN_PAGE_ROUTE_NAME].includes(this.$route.name)
    },
    isLocationPage () {
      return [LOCATION_OVERVIEW_PAGE_ROUTE_NAME].includes(this.$route.name)
    },
    isStatisticsPage () {
      return [STATISTICS_OVERVIEW_PAGE_ROUTE_NAME].includes(this.$route.name)
    },
    isPageWithMainNavBar () {
      return (this.isMainPage || this.isLocationPage || this.isStatisticsPage)
    }
  },
  beforeMount () {
    if (window !== window.top) {
      try {
        let dummy = window.localStorage
        dummy = null
        console.log('Cookies configuration is ok!', dummy)
      } catch (e) {
        this.showThirdCookiesTranslatedAlert()
      }
    }
    setTimeout(() => {
      this.getDeviceQuantity()
    }, 1000
    )
  },
  methods: {
    onTryAgain () {
      console.log('reconnect the sensor again')
    },
    onTryAgainWSconnection () {
      this.showWebSocketDisconnectedModal = false
      this.captureFingerprint() // @TODO control to launch only on capture screens
    },
    logout () {
      auth.logout()
    },
    async getDeviceQuantity () {
      await this.getDeviceCount()
    },
    showThirdCookiesTranslatedAlert () {
      let message = ''
      switch (navigator.language.split('-')[0]) {
        case 'es':
          message = 'Por favor, habilite las cookies de terceros en su navegador'
          break
        case 'pt':
          message = 'Por favor, habilite cookies de terceiros em seu navegador'
          break
        default:
          // en
          message = 'Please enable third-party cookies in your browser'
      }
      alert(message)
    }
  }
}
</script>

<style lang="scss">
#app {
  height: 100%;
  width: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  background: $background-app-color;
}
.he-ui-page {
  width: 100%;
  &.padding--displayed {
    padding: 20px;
  }
}
.he-ui-page-content {
    position: relative;
    overflow: auto;
    height: 100%;
    &.padding--displayed {
      height: calc(100% - 100px);
    }
  }

</style>
